import React from 'react'
import Appointment from './Appointment'

const AppointmentsBox = ({appointments, refreshTrigger }) => {
  return (
    <div>
      <div className='w-full h-[700px] flex flex-col overflow-y-auto border-b border-r border-l border-[#DDDDDD]'>
        {appointments.map(item => (
          <Appointment key={item.id} {...item} refreshTrigger={refreshTrigger} />
        ))}
      </div>
    </div>
  )
}

export default AppointmentsBox