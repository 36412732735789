import React from 'react';
import { Link } from 'react-router-dom';

const Terms = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Takvimim Uygulaması Kullanım Koşulları</h1>

      <p className="mb-4">
        Bu belge, randevu uygulaması ("Uygulama") kullanım koşullarını belirtmektedir. Lütfen bu
        koşulları dikkatlice okuyun ve Uygulamayı kullanmaya başlamadan önce bu şartlara uyun.
      </p>

      <h2 className="text-xl font-semibold mb-2">1. Hesaplar ve Güvenlik</h2>
      <p className="mb-4">
        1.1 Uygulamayı kullanmak için bir hesap oluşturmalısınız. Hesap bilgilerinizin güvenliği
        sizin sorumluluğunuzdadır.
      </p>

      <h2 className="text-xl font-semibold mb-2">2. Randevu Alma ve Verme</h2>
      <p className="mb-4">
        2.1 Her kullanıcı kendi takvimine randevu ekleyebilir ve diğer kullanıcıların takvimlerinden
        randevu alabilir.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. Sorumluluk Reddi</h2>
      <p className="mb-4">
        3.1 Uygulama, kullanıcıların randevu planlamalarından veya diğer kullanıcılarla olan
        etkileşimlerinden kaynaklanan herhangi bir kayıp veya hasardan sorumlu değildir.
      </p>

      <h2 className="text-xl font-semibold mb-2">4. Gizlilik Politikası</h2>
      <p className="mb-4">
        4.1 Uygulama, kullanıcı verilerini gizli tutma taahhüdünde bulunur. Gizlilik politikamızı
        inceleyerek daha fazla bilgi edinebilirsiniz.
      </p>

      <h2 className="text-xl font-semibold mb-2">5. Hüküm ve Koşulların Değiştirilmesi</h2>
      <p className="mb-4">
        5.1 Uygulama, kullanım koşullarını önceden bildirimde bulunmaksızın değiştirme hakkını
        saklı tutar.
      </p>

      <h2 className="text-xl font-semibold mb-2">6. Kullanıcı Sorumlulukları</h2>
      <p className="mb-4">
        6.1 Kullanıcılar, Uygulamayı kullanırken yerel yasalara uymak ve diğer kullanıcıların haklarına
        saygı göstermekle yükümlüdür.
      </p>

      <h2 className="text-xl font-semibold mb-2">7. İptal ve İade Politikası</h2>
      <p className="mb-4">
        7.1 Kullanıcılar, randevularını iptal etme ve iade politikasına uymakla sorumludur. Detaylar için
        destek ekibimizle iletişime geçebilirsiniz.
      </p>

      <h2 className="text-xl font-semibold mb-2">8. Fikri Mülkiyet Hakları</h2>
      <p className="mb-4">
        8.1 Uygulama üzerinde bulunan her türlü içerik ve materyal, Uygulama tarafından saklanan fikri
        mülkiyet haklarına tabidir.
      </p>

      <h2 className="text-xl font-semibold mb-2">9. Hesap Kapatma Hakkı</h2>
      <p className="mb-4">
        9.1 Uygulama, herhangi bir nedenle kullanıcı hesaplarını kapatma hakkını saklı tutar.
      </p>

      <h2 className="text-xl font-semibold mb-2">Gizlilik Politikası</h2>
      <p className="mb-4">
        Uygulama gizlilik politikasını incelemek için lütfen{' '}
        <Link to="/privacy-policy" className="text-blue-500 underline">
          Gizlilik Politikası
        </Link>{' '}
        sayfasını ziyaret edin.
      </p>

      <p className="mt-8">
        Bu kullanım koşulları son güncellenme tarihine göre geçerlidir. Uygulamayı kullanarak, bu
        koşulları kabul etmiş sayılırsınız.
      </p>
    </div>
  );
};

export default Terms;
