import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-4">Takvimim Uygulaması Gizlilik Politikası</h1>

      <p className="mb-4">
        Bu gizlilik politikası, randevu uygulaması ("Uygulama") tarafından toplanan, kullanılan ve
        paylaşılan kişisel bilgileri açıklar.
      </p>

      <h2 className="text-xl font-semibold mb-2">1. Toplanan Bilgiler</h2>
      <p className="mb-4">
        1.1 Uygulama, kullanıcıların hesap oluştururken ve Uygulamayı kullanırken sağladığı kişisel
        bilgileri toplayabilir.
      </p>

      <h2 className="text-xl font-semibold mb-2">2. Kullanım Amaçları</h2>
      <p className="mb-4">
        2.1 Uygulama, topladığı kişisel bilgileri kullanıcı deneyimini iyileştirmek, randevu
        planlamalarını sağlamak ve güvenlik amacıyla kullanabilir.
      </p>

      <h2 className="text-xl font-semibold mb-2">3. Bilgi Paylaşımı</h2>
      <p className="mb-4">
        3.1 Uygulama, kullanıcıların açık rızası olmadan kişisel bilgilerini üçüncü taraflarla
        paylaşmaz.
      </p>

      <h2 className="text-xl font-semibold mb-2">4. Güvenlik</h2>
      <p className="mb-4">
        4.1 Uygulama, topladığı kişisel bilgileri güvenli bir şekilde saklamak için gerekli
        önlemleri alır.
      </p>

      <h2 className="text-xl font-semibold mb-2">5. Çerezler</h2>
      <p className="mb-4">
        5.1 Uygulama, çerezleri kullanarak kullanıcı deneyimini iyileştirmeyi hedefleyebilir.
      </p>

      <h2 className="text-xl font-semibold mb-2">6. Değişiklikler</h2>
      <p className="mb-4">
        6.1 Gizlilik politikası, Uygulama tarafından gerekli görüldüğünde güncellenebilir. Bu
        güncellemeler sitemizde yayınlanır.
      </p>

      <h2 className="text-xl font-semibold mb-2">İletişim</h2>
      <p className="mb-4">
        Sorularınız veya endişeleriniz için lütfen atakankurtofficial@gmail.com üzerinden bizimle
        iletişime geçin.
      </p>

      <p className="mt-8">
        Bu gizlilik politikası 21.01.2023'de son güncellenmiştir.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
